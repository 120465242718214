<template>
  <div>
    <b-form-group
      id="sigle-legam-group"
      label="Sigle LEGaM:"
      label-for="sigle-legam"
    >
      <b-form-input
        id="sigle-legam"
        v-model="model.sigleLegam"
        placeholder="Sigle LEGaM"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="bib-description-group"
      label="BIB Description:"
      label-for="bib-description"
    >
      <b-form-textarea
        id="bib-description"
        placeholder="BIB description"
        v-model="model.bibDescription"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="comment-general-group"
      label="Comment general:"
      label-for="comment-general"
    >
      <b-form-textarea
        id="comment-general"
        placeholder="Comment general"
        v-model="model.commentGeneral"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<script>
  import HelpStore from '@/store/helpstore.js'

  export default {
    name: 'StudyNewEdit',
    props: ['study'],
    data(){
      return {
        // TODO: fetch from API
        model: (this.study ? this.study : {
          sigleLegam: null,
          bibDescription: null,
          commentGeneral: null
        })
      }
    },
    updated(){
      HelpStore.item = this.model
    }
  }
</script>
