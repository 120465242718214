<template>
  <div>
    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col cols="4" class="p-0">
          <PageTitle v-bind:title="'Studies'" />
        </b-col>
        <b-col cols="8" class="text-right" v-if="!config.editable">
          <BibLabelSelectPI class="mb-3" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <div v-if="$store.state.user.settings.isBiblabelFiltered && config.editable" class="mb-3 text-orange">
            <b-icon icon="exclamation-circle-fill" aria-hidden="true"></b-icon>
            Entries in this table are filtered by personal preference for bibliography set in user settings
            ({{ $store.getters.getBibLabelsByIds($store.state.user.settings.biblabels).map(biblabel => biblabel.label).join(', ') }})
          </div>
          <b-button v-if="config.editable" variant="primary" :disabled="!$store.getters.checkRole('legam.bib.study.add')" size="sm" class="float-right mb-4" v-b-modal.modal-new-study>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new study
          </b-button>
          <b-modal v-if="config.editable" id="modal-new-study" title="New study" ok-title="Save" @ok="saveStudy" size="lg">
            <StudyNewEdit />
          </b-modal>
          <LegamDataTables
            v-if="config.editable"
            v-bind="$data"
            :additionalData="tableAdditionalData"
            @selectChanged="selectChanged"
          />
          <LegamDataTablesClientSide
            v-else
            v-bind="$data"
            :additionalData="tableAdditionalData"
            @click="openDetails"
            :additionalDataFilter="tableAdditionalDataFilter"
            ref="PITable"
          />
        </b-col>
      </b-row>
    </b-container>
    <div class="merge-float" v-if="selected.length && config.editable">
      <b-badge href="#" variant="info" class="mr-1" v-for="study in selected" :key="study.id" @click="removeSelected(study)">
        <span v-html="study.sigleLegam"></span>
        <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
      </b-badge>
      <span class="btn-legam-xxs btn-legam-xs btn-legam-blue" v-if="selected.length > 1" v-b-modal.modal-merge>
        <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
        Merge studies
      </span>
      <span class="btn-legam-xxs btn-legam-xs" @click="selected = []">
        <b-icon icon="x" aria-hidden="true"></b-icon>
      </span>
      <b-modal id="modal-merge" title="Merge studies" ok-title="Merge" @ok="merge" size="md">
        <b-badge href="#" variant="info" class="mr-1" v-for="study in selected" :key="'modal' + study.id" @click="removeSelected(study)">
          <span v-html="study.sigleLegam"></span>
          <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
        </b-badge>
        <b-form-group
          id="main-entity-grp"
          label="Merge to"
          label-for="main-entity"
          class="mt-3"
          description="This study will keep all it's data (other studies will move their data to this study and then disappear)"
        >
          <b-form-select
            v-model="mainEntityId"
            :options="selected.map(study => {
              return {
                'text': study.sigleLegam,
                'value': study.id
              }
            })"
          ></b-form-select>
        </b-form-group>
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 150px;
  }
  .merge-float {
    position: fixed;
    bottom: 15px;
    left: 20px;
    background-color: #bebebe9a;
    padding: 3px 8px 5px;
    border-radius: 3px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import BibLabelSelectPI from '@/components/Bibliography/BibLabelSelectPI.vue'
  import tdColAction from "@/components/tdColAction"
  import StudyNewEdit from "@/components/Bibliography/StudyNewEdit"
  import HelpStore from '@/store/helpstore.js'
  import config from '@/config.js'

  export default {
    name: 'Studies',
    data() {
      return {
        columns: [
          { title: 'Sigle', field: 'sigleLegam', sortable: true, searchable: true },
          { title: 'Description', field: 'bibDescription', sortable: true, searchable: true },
          { title: 'Scholars', field: 'authors', sortable: true, searchable: true },
          { title: 'Label', field: 'biblabels', sortable: true, searchable: true },
          {
            title: 'Action', field: 'id', tdComp: tdColAction, thClass: 'tdAction',
            transformData: function (row) {
              return [
                { text: null, icon: 'list-ul', id: row.sigleLegam, to: {name: 'StudyDetails', params: {
                    slug: row.sigleLegamNormalized || "-",
                    id: row.id
                  }}
                }
              ]
            }
          }
        ],
        columnsPI: [
          { title: 'Sigle', field: 'sigleLegam', sortable: true, searchable: true },
          { title: 'Description', field: 'bibDescription', sortable: true, searchable: true },
          { title: 'Scholars', field: 'authors', sortable: true, searchable: true },
          { title: 'Label', field: 'biblabels', sortable: true, searchable: false },
        ],
        url: '/studies/datatables',
        key: 1,
        searchableByColumn: true,
        selected: [],
        mainEntityId: null,
        config: config,
        selectedBibLabels: [],
      }
    },
    computed: {
      ...mapState({
        userSettings: state => state.user.settings,
        userSettingsPI: state => state.user.settingsPI,
      }),
      tableAdditionalData(){
        return {
          'biblabel': this.userSettings.biblabels,
          'biblabelSuppressed': this.userSettings.biblabelsSuppressed,
        }
      }
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      StudyNewEdit,
      BibLabelSelectPI,
    },
    methods: {
      tableAdditionalDataFilter(data) {
        if (this.userSettingsPI.biblabels.length) {
          data = data.filter(row => this.userSettingsPI.biblabels.includes(row.biblabels))
        }
        return data
      },
      saveStudy(){
        this.$store.dispatch('createStudy', HelpStore.item).then((response) => {
          router.push(`/study/${response.data.sigleLegamNormalized}/${response.data.id}`)
        })
      },
      selectChanged(items){
        this.selected = items
        if (items.length){
          this.mainEntityId = items[0].id
        }
      },
      openDetails(data) {
        router.push(`/study/-/${data.id}`)
      },
      removeSelected(item) {
        this.selected = this.selected.filter(_item => item.id != _item.id)
      },
      merge() {
        this.$store.dispatch('mergeStudies', {
          mainStudyId: this.mainEntityId,
          studyIds: this.selected.filter(study => study.id != this.mainEntityId).map(study => study.id)
        }).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Studies merged`
          })
          this.selected = []
          this.mainEntityId = null
          this.key++
        })
      },
    },
    watch: {
      "userSettings"(){
        this.key++
      },
      userSettingsPI: {
        deep: true,
        handler() {
          if (this.$refs.PITable.table) {
            this.$refs.PITable.sortPageFilter()
          }
        }
      },
    }
  }
</script>
